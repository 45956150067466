import type {UpdateAuthProviderParameters} from '@/Services/AuthProviderService';
import {parseDate} from '@/Utility/Helpers';
import type {OAuthProviderDriverId} from '@/Models/AuthProviders/OAuthProviderDriver';
import OAuthProviderDriverList from '@/Models/AuthProviders/OAuthProviderDriver';

export default class OAuthProvider {

    readonly uid: string;
    readonly name: string;
    readonly domain: string;
    readonly driver_id: OAuthProviderDriverId;
    readonly client_id: string;
    readonly configuration: object | null;
    readonly user_provisioning_enabled: boolean;

    readonly created_at: Date;
    readonly updated_at: Date;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.name = attributes.name;
        this.domain = attributes.domain;
        this.driver_id = attributes.driver_id;
        this.client_id = attributes.client_id;
        this.configuration = attributes.configuration;
        this.user_provisioning_enabled = attributes.user_provisioning_enabled;

        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }

    get driver() {
        return OAuthProviderDriverList[this.driver_id];
    }

    toUpdateAuthProviderParameters(): UpdateAuthProviderParameters {
        return {
            name: this.name,
            domain: this.domain,
            client_id: this.client_id,
            configuration: this.configuration,
        };
    }
}
