<template>
    <main
        id="layout-main"
        :data-saving="isSaving"
    >

        <div id="layout-content">
            <div id="content">

                <div class="column">
                    <form :key="authProvider.updated_at" ref="form">

                        <h1 class="headline">
                            {{ trans('auth_providers.update.headline') }}
                        </h1>

                        <div class="fields">
                            <div class="driver">
                                <label>{{ trans('labels.authorization_driver') }}</label>
                                <img
                                    :alt="authProvider.driver.name"
                                    :src="authProvider.driver.logo"
                                    :title="authProvider.driver.name"
                                >
                            </div>

                            <TextInput
                                :label="trans('labels.name')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('auth_providers.placeholders.name')"
                                :validation-errors="validationErrors('name')"
                                property="name"
                                required
                                @change="removeValidationErrors('name')"
                            />

                            <TextInput
                                :label="trans('labels.domain')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('auth_providers.placeholders.domain')"
                                :validation-errors="validationErrors('domain')"
                                property="domain"
                                required
                                @change="removeValidationErrors('domain')"
                            />

                            <TextInput
                                :label="trans('labels.client_id')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('auth_providers.placeholders.client_id')"
                                :validation-errors="validationErrors('client_id')"
                                property="client_id"
                                required
                                @change="removeValidationErrors('client_id')"
                            />

                            <TextInput
                                :label="trans('labels.client_secret')"
                                :maxlength="255"
                                :model="$data"
                                :placeholder="trans('auth_providers.placeholders.client_secret')"
                                :validation-errors="validationErrors('client_secret')"
                                property="clientSecret"
                                required
                                type="password"
                                @change="removeValidationErrors('client_secret')"
                                @focus="e => e.target.select()"
                            />

                            <TextInput
                                :label="trans('labels.entra_tenant_id')"
                                :maxlength="255"
                                :model="form.configuration"
                                :placeholder="trans('auth_providers.placeholders.tenant')"
                                :validation-errors="validationErrors('configuration.tenant')"
                                property="tenant"
                                required
                                @change="removeValidationErrors('configuration.tenant')"
                            />

                            <TextInput
                                :label="trans('labels.proxy')"
                                :maxlength="255"
                                :model="form.configuration"
                                :placeholder="trans('auth_providers.placeholders.proxy')"
                                :validation-errors="validationErrors('configuration.proxy')"
                                property="proxy"
                                type="url"
                                @change="removeValidationErrors('configuration.proxy')"
                            />
                        </div>

                        <div class="buttons">
                            <ButtonSecondary
                                :href="route('auth_providers.index')"
                                caption="auth_providers.create.btn_cancel"
                            />
                            <ButtonPrimary :caption="trans('labels.save')" @trigger.prevent="onSave" />
                        </div>
                    </form>

                    <form :key="authProvider.updated_at" ref="form">
                        <h1 class="headline">
                            {{ trans('auth_providers.delete.headline') }}
                        </h1>
                        <div class="buttons center">
                            <ButtonPrimary
                                :caption="trans('labels.delete')"
                                class="btn-red"
                                @trigger.prevent="onDeleteClick"
                            />
                        </div>
                    </form>
                </div>

                <div class="column">
                    <auth-provider-help />
                </div>

            </div>

            <!-- Modals go here -->
            <ModalDeleteAuthProvider />
            <ModalProgress />
            <ModalNotification />
        </div>

    </main>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import OAuthProvider from '@/Models/AuthProviders/OAuthProvider';
import {permission, route, trans} from '@/Utility/Helpers';
import TextInput from '@/Vue/Common/TextInput.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import {authProviderServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import type RequestError from '@/Errors/RequestError';
import EventType from '@/Utility/EventType';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ModalDeleteAuthProvider from '@/Vue/Modals/ModalDeleteAuthProvider.vue';
import PageHeaderButton from '@/Utility/PageHeaderButton';
import AuthorizationError from '@/Errors/AuthorizationError';
import {Permission} from '@/Models/User/Permission';
import AuthProviderHelp from '@/Vue/AuthProviders/AuthProviderHelp.vue';

export default defineComponent({
    components: {
        AuthProviderHelp,
        ModalDeleteAuthProvider,
        ButtonSecondary,
        ModalNotification,
        ModalProgress,
        ButtonPrimary,
        TextInput,
    },

    props: {
        authProviderJson: {
            type: String,
            required: true,
        },
    },

    data() {
        const authProvider = new OAuthProvider(JSON.parse(this.authProviderJson));
        const secretPlaceholder = 'xxxxxxxx';

        return {
            authProviderService: inject(authProviderServiceKey)!,
            authProvider: authProvider,
            form: authProvider.toUpdateAuthProviderParameters(),
            errors: {} as Record<string, string[]>,
            secretPlaceholder: secretPlaceholder,
            clientSecret: secretPlaceholder,

            events: new Map([
                [EventType.MODAL_DELETE_AUTH_PROVIDER_APPLY, this.onDeleteProviderConfirmed],
                [EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
            ]),
        };
    },

    computed: {
        formHtml() {
            return this.$refs.form as HTMLFormElement;
        },

        canDelete() {
            return permission(Permission.AuthProvidersDelete());
        },

        isSaving() {
            if (this.authProviderService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        headerButtons() {
            return {
                delete: new PageHeaderButton({
                    caption: trans('labels.delete'),
                    visible: this.canDelete,
                    icon: 'icon_delete',
                    tooltip: 'buttons.auth_providers.delete',
                    callback: this.onDeleteClick,
                }),
            };
        },
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        route,
        trans,

        validationErrors(property: string): string[] {
            return this.errors[property] || [];
        },

        removeValidationErrors(property: string) {
            delete this.errors[property];
        },

        onSave() {
            if (!this.formHtml.reportValidity()) {
                return;
            }

            if (this.clientSecret !== this.secretPlaceholder) {
                this.form.client_secret = this.clientSecret;
            }

            this.authProviderService
                .updateAuthProvider(this.authProvider.uid, this.form)
                .then(provider => {
                    this.authProvider = provider;
                    this.form = provider.toUpdateAuthProviderParameters();
                })
                .catch(this.onApiError);
        },

        onDeleteProviderConfirmed() {
            this.authProviderService
                .deleteAuthProvider(this.authProvider.uid)
                .then(() => window.location.href = route('auth_providers.index'))
                .catch(this.onApiError);
        },

        onDeleteClick() {
            this.$globalEvents.emit(EventType.MODAL_DELETE_AUTH_PROVIDER_SHOW, this.authProvider);
        },

        onApiError(error: RequestError | AuthorizationError) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
                return;
            }

            if (error.isValidationError) {
                this.errors = error.validationErrors;
                return;
            }

            this.$root!.showErrorDialog(error);
        },

        onBeforeUnload() {
            this.authProviderService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },

        /**
         * Click handler for header navigation buttons that delegates the action to the button callback method
         */
        onClickHeaderNav(buttonConfig: PageHeaderButton) {
            if (buttonConfig.callback === null) {
                return this;
            }

            buttonConfig.callback.call(this, buttonConfig);
            return this;
        },
    }
});
</script>

<style lang="css" scoped>

#content {
    display: grid;
    grid-template-columns: minmax(min-content, 700px) minmax(min-content, 480px);
    justify-content: center;
    gap: 32px;
    padding: 32px;

    .column {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    form {
        background-color: white;
        border-radius: var(--card-border-radius);
        padding: 30px 48px;

        .headline {
            margin-bottom: 32px;
            text-align: center;
        }

        .fields {
            .driver {
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-bottom: var(--forminput-spacing);

                label {
                    font-family: var(--font-family-condensed-demibold);
                    font-size: var(--font-size-default);
                    margin-bottom: 8px;
                }

                img {
                    margin: auto;
                    width: 80px;
                    padding: 8px;
                    aspect-ratio: 1;
                    border: var(--forminput-border);
                    border-radius: var(--forminput-border-radius);
                }
            }

            .textinput {
                &:deep(label) {
                    padding: 0 0 8px 0;
                    font-family: var(--font-family-condensed-demibold);

                    &:has(+ :required):after {
                        content: ' *';
                    }
                }
            }
        }

        .buttons {
            margin-top: 32px;
            display: flex;
            justify-content: end;

            &.center {
                justify-content: center;
            }
        }
    }
}

</style>
