import {trans} from '@/Utility/Helpers/trans';

export enum OAuthProviderDriverId {
    azure = 'azure',
}

class OAuthProviderDriver {

    constructor(readonly id: OAuthProviderDriverId) {
    }

    get logo() {
        switch (this.id) {
            case OAuthProviderDriverId.azure:
            default:
                return '/images/logos/logo_entra.svg';
        }
    }

    get name() {
        return trans(`auth_providers.driver_names.${this.id}`);
    }
}

const OAuthProviderDriverList: Record<OAuthProviderDriverId, OAuthProviderDriver> =
    Object.assign({}, ...Object
        .values(OAuthProviderDriverId)
        .map(key => ({ [key]: new OAuthProviderDriver(key) }))
    );

export default OAuthProviderDriverList;
