<template>
    <main
        id="layout-main"
        :data-saving="isSaving"
    >

        <PageHeader
            :page-title="trans('auth_providers.index.headline')"
        />

        <div id="layout-content">
            <div id="content">

                <ButtonPrimary
                    v-if="canCreateProvider"
                    v-tooltip="'buttons.auth_providers.create'"
                    :href="route('auth_providers.create')"
                    caption="auth_providers.index.btn_create"
                    icon="icon_add"
                />

                <div class="provider-list">
                    <auth-provider-list-item
                        v-for="provider in authProviders"
                        :key="provider.uid"
                        :provider="provider"
                        @delete-click="onDeleteClick"
                    />
                </div>

            </div>

            <!-- Modals go here -->
            <ModalDeleteAuthProvider />
            <ModalProgress />
        </div>
    </main>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import OAuthProvider from '@/Models/AuthProviders/OAuthProvider';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import {permission, route, trans} from '@/Utility/Helpers';
import AuthProviderListItem from '@/Vue/AuthProviders/AuthProviderListItem.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {authProviderServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';
import EventType from '@/Utility/EventType';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ModalDeleteAuthProvider from '@/Vue/Modals/ModalDeleteAuthProvider.vue';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        ModalDeleteAuthProvider,
        ModalProgress,
        ButtonPrimary,
        AuthProviderListItem,
        PageHeader
    },

    props: {
        authProvidersJson: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            authProviderService: inject(authProviderServiceKey)!,

            authProviders: JSON.parse(this.authProvidersJson)
                .map((provider: object) => new OAuthProvider(provider)) as OAuthProvider[],

            events: new Map([
                [EventType.MODAL_DELETE_AUTH_PROVIDER_APPLY, this.onDeleteProviderConfirmed],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
            ]),
        };
    },

    computed: {
        canCreateProvider() {
            return permission(Permission.AuthProvidersCreate());
        },

        isSaving() {
            if (this.authProviderService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        }
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        route,
        trans,

        onDeleteProviderConfirmed(provider: OAuthProvider) {
            this.authProviderService
                .deleteAuthProvider(provider.uid)
                .then(() => window.location.reload())
                .catch(this.onErrorApi);
        },

        onDeleteClick(provider: OAuthProvider) {
            this.$globalEvents.emit(EventType.MODAL_DELETE_AUTH_PROVIDER_SHOW, provider);
        },

        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }
            this.$root!.showErrorDialog(error);
        },

        onBeforeUnload() {
            this.authProviderService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },
    },

});
</script>

<style lang="css" scoped>

#content {
    .provider-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin: 12px 0 30px 0;

        max-width: var(--container-max-width);
        min-width: 670px;
    }
}

</style>
