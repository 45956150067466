<template>
    <Modal
        ref="modal"
        :animated="true"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.rename_identity_provider.title')"
        event-type="MODAL_RENAME_IDENTITY_PROVIDER"
        @show="onShow"
    >
        <!-- Title -->
        <TextInput
            ref="identity-provider-name-input"
            :error-msg="trans('errors.auth_provider.name')"
            :label="trans('labels.name')"
            :maxlength="50"
            :model="$data"
            :placeholder="trans('auth_providers.placeholders.name')"
            :required="true"
            class="panel-row identity-provider-name-input"
            property="name"
        />

        <template #buttons>
            <ButtonSecondary
                caption="modals.rename_identity_provider.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                caption="modals.rename_identity_provider.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, nextTick} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers/trans';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import EventType from '@/Utility/EventType';
import TextInput from '@/Vue/Common/TextInput.vue';
import type OAuthProvider from '@/Models/AuthProviders/OAuthProvider';

export default defineComponent({
    components: {
        TextInput,
        ButtonSecondary,
        ButtonPrimary,
        Modal,
    },

    data() {
        return {
            name: null as string | null,
        };
    },

    methods: {
        trans,

        onShow(identityProvider: OAuthProvider) {
            this.name = identityProvider.name;

            nextTick(() => {
                (this.$refs['identity-provider-name-input'] as InstanceType<typeof HTMLInputElement>).focus();
            });
        },

        onClickCancel() {
            (this.$refs.modal as InstanceType<typeof Modal>).cancel();
        },

        onClickApply() {
            (this.$refs.modal as InstanceType<typeof Modal>).hide();

            this.$globalEvents.emit(
                EventType.MODAL_RENAME_IDENTITY_PROVIDER_APPLY,
                this.name,
            );
        },
    },
});
</script>

<style lang="scss" scoped>

dialog {
    width: 450px;

    .panel-row {
        &:deep(label) {
            font-family: var(--font-family-condensed-demibold);
            display: block;
            padding-bottom: 8px;
        }

        :deep(textarea) {
            min-height: 120px;
        }
    }
}


</style>
