import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import {route, trans} from '@/Utility/Helpers';
import type {AxiosRequestConfig} from 'axios';
import axios from 'axios';
import OAuthProvider from '@/Models/AuthProviders/OAuthProvider';
import type {OAuthProviderDriverId} from '@/Models/AuthProviders/OAuthProviderDriver';

export type UpdateAuthProviderParameters = {
    name?: string;
    domain?: string;
    client_id?: string;
    client_secret?: string;
    configuration?: object | null;
}

export type CreateAuthProviderParameters = {
    driver_id?: OAuthProviderDriverId;
    name?: string;
    domain?: string;
    client_id?: string;
    client_secret?: string;
    configuration?: object | null;
}

export default class AuthProviderService {

    public isLoading: boolean = false;
    public isSaving: boolean = false;

    private abortController = new AbortController();

    /**
     * Cancel any ongoing requests
     */
    async cancelRequests(): Promise<any> {
        this.abortController.abort();
        this.abortController = new AbortController();

        return Promise.resolve('Requests canceled');
    }

    async updateAuthProvider(
        providerUid: string,
        updateParameters: UpdateAuthProviderParameters
    ): Promise<OAuthProvider> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .patch(
                route('api.auth_providers.update', { auth_provider: providerUid }),
                updateParameters,
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return this.parseProvider(data.data);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async deleteAuthProvider(providerUid: string): Promise<void> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .delete(
                route('api.auth_providers.delete', { auth_provider: providerUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(() => {
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    async createAuthProvider(
        createParameters: CreateAuthProviderParameters
    ): Promise<OAuthProvider> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .post(
                route('api.auth_providers.create'),
                createParameters,
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return this.parseProvider(data.data);
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    /**
     * Tries to parse the given provider data into a valid auth provider.
     * Will print and throw usable errors when this fails.
     */
    private parseProvider(providerData: any): OAuthProvider {
        try {
            return new OAuthProvider(providerData);
        } catch (ex) {
            console.error(
                'AuthProviderService->parseProvider(): API returned invalid or incompatible device data.',
                providerData,
                ex
            );
            throw new Error(trans('errors.auth_provider.invalid_data'));
        }
    }


    /**
     * @returns {Promise<string>} newly created api key for user provisioning
     */
    async enableUserProvisioning(providerUid: string): Promise<string> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .post(
                route('api.auth_providers.user_provisioning.create', { auth_provider: providerUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(({ data }) => {
                return data;
            })

            .finally(() => {
                this.isSaving = false;
            });
    }

    async removeUserProvisioning(providerUid: string): Promise<void> {
        if (this.isSaving) {
            throw new ServiceIsBusyError('Saving is still in progress.');
        }

        this.isSaving = true;

        return axios
            .delete(
                route('api.auth_providers.user_provisioning.delete', { auth_provider: providerUid }),
                { signal: this.abortController.signal } as AxiosRequestConfig
            )
            .then(() => {
            })
            .finally(() => {
                this.isSaving = false;
            });
    }
}
